import React from 'react';

import SEO from '../components/shared/seo';

const NotFoundPage = (): JSX.Element => (
  <>
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </>
);

export default NotFoundPage;
